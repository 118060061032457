var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[(!_vm.isDefalutPosq)?_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('label',{staticStyle:{"white-space":"nowrap","margin-right":"5px"},attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.description)?_c('span',{staticStyle:{"margin-right":"5px"}},[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e(),_c('el-input',{style:(_vm.getStyle),attrs:{"placeholder":_vm.data.placeholder},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})],1),_c('div',[(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e()])]):_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 4 : _vm.data.description ? 20 : 24}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),_c('el-col',{attrs:{"span":_vm.showLabel && _vm.isDefalutPosq ? 4 : 0}}),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPosq
      ?
      12
      :
      24}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValue),expression:"selectedValue"}],staticClass:"slv",style:({'text-align': _vm.inputTextAlignment(), ..._vm.getStyle}),attrs:{"placeholder":_vm.data.placeholder},domProps:{"value":(_vm.selectedValue)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedValue=$event.target.value}}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }